import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import './styles.scss';

const ResourceSearch = ({
  clearFilter,
  clearLabel,
  filterChangeHandler,
  filterValue,
  placeHolder,
  searchApplied,
  searchAppliedHandler,
  searchLabel,
  searchResultsLabel,
}) => {
  return (
    <div>
      <div className="resourcesearch__input_container">
        <input
          className="resourcesearch__input"
          type="text"
          value={filterValue}
          onChange={filterChangeHandler}
          placeholder={`${placeHolder}`.toUpperCase()}
        />
        <Button
          className="resourcesearch__search_button"
          onClick={searchAppliedHandler}
        >
          {searchLabel}
        </Button>
      </div>
      {searchApplied && (
        <div className="resourcesearch__clear_container mt-2">
          <h4 className="resourcesearch__clear_text mb-0">
            {`${searchResultsLabel}`}
            <b>{`'${filterValue}'`}</b>
          </h4>
          <Button
            className="resourcesearch__clear_button"
            onClick={clearFilter}
          >
            {clearLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

ResourceSearch.propTypes = {
  clearFilter: PropTypes.func,
  clearLabel: PropTypes.string,
  filterChangeHandler: PropTypes.func,
  filterValue: PropTypes.string,
  placeHolder: PropTypes.string,
  searchApplied: PropTypes.bool,
  searchAppliedHandler: PropTypes.func,
  searchLabel: PropTypes.string,
  searchResultsLabel: PropTypes.string,
};

export default ResourceSearch;
