import React, { useContext } from 'react';
import Button from '../Button';
import OauthContext from '../../context/OauthContext';

const DevLogin = () => {
  const oauthContext = useContext(OauthContext);

  const clickHandler = () => {
    oauthContext.setLoggedIn(!oauthContext.loggedIn);
  };
  return <Button onClick={clickHandler}>LoginToggle</Button>;
};

export default DevLogin;
