import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../Image';
import './styles.scss';

const AdditionalResources = ({ img, heading, to }) => {
  return (
    <div className="additional-resource">
      <Link to={to}>
        <Image filename={img} className="additional-resource__image" />
        <div className="additional-resource__heading text-bold">
          {heading.toUpperCase()}
        </div>
      </Link>
    </div>
  );
};

AdditionalResources.defaultProps = {
  img: null,
  heading: null,
  to: null,
};

AdditionalResources.propTypes = {
  /** The image's file name */
  img: PropTypes.string,
  /** The heading */
  heading: PropTypes.string,
  /** Where the component links to */
  to: PropTypes.string,
};

export default AdditionalResources;
