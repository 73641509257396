import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import Quicklinks from '../../components/Quicklinks';
import AdditionalResources from '../../components/AdditionalResources';
import { Container, Row, Column } from '../../components/Grid';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import { graphql } from 'gatsby';
import { RESOURCE_FILTER_FIELDS } from '../../utils/constants';
import {
  getKeyedResources,
  getRawResources,
} from '../../utils/resource-helper';
import Resource from '../../components/Resource';
import Hero from '../../components/Hero';
import Callout from '../../components/Callout';
import { useTranslation } from 'react-i18next';
import ResourceSearch from '../../components/ResourceSearch';
import LoginLogoutBlock from '../../components/LoginLogoutBlock';
import virtualIcon from '../../images/icon-virtual-activity.png';

const renderRowOfResources = (resources) => {
  return (
    <Row key={resources[0].title + resources[0].slug}>
      {resources.map((res, index) => (
        <Column size={4} key={res.title + index}>
          <Resource
            tophat={res.tophat}
            title={res.title}
            duration={res.duration}
            description={res.description}
            img={res.img}
            actions={res.actions}
            locked
          />
        </Column>
      ))}
    </Row>
  );
};

const renderFilteredResources = (filteredResources, noResourcesFound) => {
  if (!filteredResources.length) {
    return noResourcesFound;
  }

  return (
    <>
      {filteredResources
        .reduce((acc, curr, index) => {
          const accIndex = Math.floor(index / 3);
          if (acc[accIndex] === undefined) {
            acc[accIndex] = [curr];
          } else {
            acc[accIndex].push(curr);
          }
          return acc;
        }, [])
        .map((resRow) => renderRowOfResources(resRow))}
    </>
  );
};

const Volunteers = ({ data, pageContext }) => {
  const allResources = getRawResources(data);
  const keyedResources = getKeyedResources(data);
  const [resources, setResources] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [searchApplied, setSearchApplied] = useState(false);
  const { t } = useTranslation('employees');

  const filterChangeHandler = (event) => setFilterValue(event.target.value);
  const clearFilter = () => {
    setFilterValue('');
    setSearchApplied(false);
  };
  const searchAppliedHandler = () => {
    if (filterValue) {
      const filteredResources = allResources.filter((res) =>
        RESOURCE_FILTER_FIELDS.some((field) =>
          res[field].toLowerCase().includes(filterValue.toLowerCase())
        )
      );
      setResources(filteredResources);
      setSearchApplied(true);
    }
  };

  const textOverlay = (
    <div className="employees__text_overlay_container">
      <Container fullWidth={true}>
        <div className="employees__text_overlay_inner_container">
          <div className="employees__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="employees__text_overlay_title">
            {t('heroTitle1')}{' '}
          </span>
          <span className="employees__text_overlay_title2 text-bold color-light-blue">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-employees-1.jpg', text: '', title: '' },
    { image: 'img-lead-employees-2.jpg', text: '', title: '' },
    { image: 'img-lead-employees-3.jpg', text: '', title: '' },
    { image: 'img-lead-employees-4.jpg', text: '', title: '' },
  ];

  const {
    nutritiousEating,
    nutritiousEatingCompanionEdGuide,
    mysteryHiddenSugars,
    designInnovation,
    nutritiousCelebrations,
    supermarketSmart,
    scienceBehindHealthyEating,
    planningForAHealthierYou,
    nutritiousAlternatives,
    exerciseForHealth,
    exerciseForHealthCompanionEdGuide,
    getMoving,
    youtubeStar,
    heartPumpingTheatre,
    energyInMotion,
    stayingHealthyWhileStayingConnected,
    peerPromotion,
    ncdsNinja,
    escapeUnhealthyHabits,
    underPressure,
    imThirsty,
    moleculeModels,
    keepYourHeartPumping,
    bodySystems,
    deepDiveIntoType2Diabetes,
    whatsTheDifference,
    riskyChoices,
    bedtime,
    healthNewsInTheKnow,
    stressOut,
  } = keyedResources;

  const fadeInDelays = [0, 300, 600];

  const removeARDX = true;

  return (
    <Layout title="Volunteers" className={pageContext.lang}>
      <Hero mediaSource={heroImages} textOverlay={textOverlay} />
      <Section>
        {/* INTRO AND QUICK LINKS */}
        <Container>
          <Row>
            <Column size={8}>
              <h2 className="pb-1">
                {t('volunteersIntroHeading').toUpperCase()}
              </h2>
              <p>{t('volunteersIntroBody')}</p>
            </Column>
            <Column size={4}>
              <LoginLogoutBlock ardx={removeARDX} />
            </Column>
          </Row>
          <ResourceSearch
            clearFilter={clearFilter}
            clearLabel={t('volunteersResourceSearchClearButtonLabel')}
            filterChangeHandler={filterChangeHandler}
            filterValue={filterValue}
            placeHolder={t('volunteersResourceSearchPlaceholder')}
            searchApplied={searchApplied}
            searchAppliedHandler={searchAppliedHandler}
            searchLabel={t('volunteersResourceSearchButtonLabel')}
            searchResultsLabel={t('volunteersResourceSearchSearchResult')}
          />
          {!searchApplied && (
            <Quicklinks
              className="text-sans-serif mt-2"
              title={t('volunteersIntroQuickLinksLabel').toUpperCase()}
              smoothScroll={true}
              links={[
                {
                  target: '#nutrition',
                  label: t('volunteersIntroQuickLinksLink1').toUpperCase(),
                },
                {
                  target: '#physical-activities',
                  label: t('volunteersIntroQuickLinksLink2').toUpperCase(),
                },
                {
                  target: '#know-the-specifics',
                  label: t('volunteersIntroQuickLinksLink3').toUpperCase(),
                },
                {
                  target: '#healthy-lifestyle',
                  label: t('volunteersIntroQuickLinksLink4').toUpperCase(),
                },
              ]}
            />
          )}
        </Container>
        <Container>
          <Row>
            <div className="pt-1" style={{ display: 'flex' }}>
              <img
                className="virtual-icon-legend"
                src={virtualIcon}
                alt="Virtual Activity"
              />
              <span>
                <i>
                  {pageContext.lang === 'uk'
                    ? 'Virtual Activities'
                    : 'Virtual-Led Activities'}
                </i>
              </span>
            </div>
          </Row>
        </Container>
      </Section>
      {searchApplied ? (
        <Section>
          <Container>
            {renderFilteredResources(
              resources,
              t('volunteersResourceSearchNoResources')
            )}
          </Container>
        </Section>
      ) : (
        <>
          <Section className="bg-color-purple" id="nutrition">
            {/* Nutrition */}
            <Container>
              <ToggleClassOnVisible extraClasses="appear-on-visible">
                <h2 className="color-white">
                  {t('volunteersNutritionHeading').toUpperCase()}
                </h2>
                <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
                  <Column size={7}>
                    <Resource
                      title={nutritiousEating.title}
                      duration={nutritiousEating.duration}
                      description={nutritiousEating.description}
                      img={nutritiousEating.img}
                      actions={nutritiousEating.actions}
                      withBorder={false}
                    />
                  </Column>
                  <Column size={5}>
                    <Resource
                      title={nutritiousEatingCompanionEdGuide.title}
                      description={nutritiousEatingCompanionEdGuide.description}
                      img={nutritiousEatingCompanionEdGuide.img}
                      actions={nutritiousEatingCompanionEdGuide.actions}
                      withBorder={false}
                      alignedButtons={false}
                    />
                  </Column>
                </Row>
              </ToggleClassOnVisible>
            </Container>
          </Section>
          <Section>
            {/* Nutrition resources */}
            <Container>
              <Row>
                <Column size={4}>
                  {' '}
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={mysteryHiddenSugars.tophat}
                      subject={mysteryHiddenSugars.subject}
                      title={mysteryHiddenSugars.title}
                      duration={mysteryHiddenSugars.duration}
                      description={mysteryHiddenSugars.description}
                      img={mysteryHiddenSugars.img}
                      actions={mysteryHiddenSugars.actions}
                      locked
                    />{' '}
                  </ToggleClassOnVisible>
                </Column>

                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={designInnovation.tophat}
                      subject={designInnovation.subject}
                      title={designInnovation.title}
                      duration={designInnovation.duration}
                      description={designInnovation.description}
                      img={designInnovation.img}
                      actions={designInnovation.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={nutritiousCelebrations.tophat}
                      subject={nutritiousCelebrations.subject}
                      title={nutritiousCelebrations.title}
                      duration={nutritiousCelebrations.duration}
                      description={nutritiousCelebrations.description}
                      img={nutritiousCelebrations.img}
                      actions={nutritiousCelebrations.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              <Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={supermarketSmart.tophat}
                      subject={supermarketSmart.subject}
                      title={supermarketSmart.title}
                      duration={supermarketSmart.duration}
                      description={supermarketSmart.description}
                      img={supermarketSmart.img}
                      actions={supermarketSmart.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={scienceBehindHealthyEating.tophat}
                      subject={scienceBehindHealthyEating.subject}
                      title={scienceBehindHealthyEating.title}
                      duration={scienceBehindHealthyEating.duration}
                      description={scienceBehindHealthyEating.description}
                      img={scienceBehindHealthyEating.img}
                      actions={scienceBehindHealthyEating.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={planningForAHealthierYou.tophat}
                      subject={planningForAHealthierYou.subject}
                      title={planningForAHealthierYou.title}
                      duration={planningForAHealthierYou.duration}
                      description={planningForAHealthierYou.description}
                      img={planningForAHealthierYou.img}
                      actions={planningForAHealthierYou.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              <Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={nutritiousAlternatives.tophat}
                      subject={nutritiousAlternatives.subject}
                      title={nutritiousAlternatives.title}
                      duration={nutritiousAlternatives.duration}
                      description={nutritiousAlternatives.description}
                      img={nutritiousAlternatives.img}
                      actions={nutritiousAlternatives.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
            </Container>
          </Section>
          <Section className="bg-color-purple" id="physical-activities">
            {/* Physical Activity */}
            <Container>
              <ToggleClassOnVisible extraClasses="appear-on-visible">
                <h2 className="color-white">
                  {t('volunteersPhysicalActivityHeading').toUpperCase()}
                </h2>
                <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
                  <Column size={7}>
                    <Resource
                      title={exerciseForHealth.title}
                      duration={exerciseForHealth.duration}
                      description={exerciseForHealth.description}
                      img={exerciseForHealth.img}
                      actions={exerciseForHealth.actions}
                      withBorder={false}
                    />
                  </Column>
                  <Column size={5}>
                    <Resource
                      title={exerciseForHealthCompanionEdGuide.title}
                      description={
                        exerciseForHealthCompanionEdGuide.description
                      }
                      img={exerciseForHealthCompanionEdGuide.img}
                      actions={exerciseForHealthCompanionEdGuide.actions}
                      withBorder={false}
                      alignedButtons={false}
                    />
                  </Column>
                </Row>
              </ToggleClassOnVisible>
            </Container>
          </Section>
          <Section>
            {/*Physical Activity Resources */}
            <Container>
              <Row>
                <Column size={4}>
                  {' '}
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={getMoving.tophat}
                      subject={getMoving.subject}
                      title={getMoving.title}
                      duration={getMoving.duration}
                      description={getMoving.description}
                      img={getMoving.img}
                      actions={getMoving.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={youtubeStar.tophat}
                      subject={youtubeStar.subject}
                      title={youtubeStar.title}
                      duration={youtubeStar.duration}
                      description={youtubeStar.description}
                      img={youtubeStar.img}
                      actions={youtubeStar.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={heartPumpingTheatre.tophat}
                      subject={heartPumpingTheatre.subject}
                      title={heartPumpingTheatre.title}
                      duration={heartPumpingTheatre.duration}
                      description={heartPumpingTheatre.description}
                      img={heartPumpingTheatre.img}
                      actions={heartPumpingTheatre.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              <Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={energyInMotion.tophat}
                      subject={energyInMotion.subject}
                      title={energyInMotion.title}
                      duration={energyInMotion.duration}
                      description={energyInMotion.description}
                      img={energyInMotion.img}
                      actions={energyInMotion.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={stayingHealthyWhileStayingConnected.tophat}
                      subject={stayingHealthyWhileStayingConnected.subject}
                      title={stayingHealthyWhileStayingConnected.title}
                      duration={stayingHealthyWhileStayingConnected.duration}
                      description={
                        stayingHealthyWhileStayingConnected.description
                      }
                      img={stayingHealthyWhileStayingConnected.img}
                      actions={stayingHealthyWhileStayingConnected.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={peerPromotion.tophat}
                      subject={peerPromotion.subject}
                      title={peerPromotion.title}
                      duration={peerPromotion.duration}
                      description={peerPromotion.description}
                      img={peerPromotion.img}
                      actions={peerPromotion.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              <Row className="last">
                <Column size={4} className="ncds-ninja">
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={ncdsNinja.tophat}
                      subject={ncdsNinja.subject}
                      title={ncdsNinja.title}
                      duration={ncdsNinja.duration}
                      description={ncdsNinja.description}
                      img={ncdsNinja.img}
                      actions={ncdsNinja.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={escapeUnhealthyHabits.tophat}
                      subject={escapeUnhealthyHabits.subject}
                      title={escapeUnhealthyHabits.title}
                      duration={escapeUnhealthyHabits.duration}
                      description={escapeUnhealthyHabits.description}
                      img={escapeUnhealthyHabits.img}
                      actions={escapeUnhealthyHabits.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
            </Container>
          </Section>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Section className="bg-color-purple" id="know-the-specifics">
              {/* Know the Specifics */}
              <Container>
                <h2 className="color-white">
                  {t('volunteersKnowTheSpecificsHeading').toUpperCase()}
                </h2>
              </Container>
            </Section>
          </ToggleClassOnVisible>
          <Section>
            <Container>
              <Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={underPressure.tophat}
                      subject={underPressure.subject}
                      title={underPressure.title}
                      duration={underPressure.duration}
                      description={underPressure.description}
                      img={underPressure.img}
                      actions={underPressure.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={imThirsty.tophat}
                      subject={imThirsty.subject}
                      title={imThirsty.title}
                      duration={imThirsty.duration}
                      description={imThirsty.description}
                      img={imThirsty.img}
                      actions={imThirsty.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={moleculeModels.tophat}
                      subject={moleculeModels.subject}
                      title={moleculeModels.title}
                      duration={moleculeModels.duration}
                      description={moleculeModels.description}
                      img={moleculeModels.img}
                      actions={moleculeModels.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              <Row>
                <Column size={4}>
                  {' '}
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={keepYourHeartPumping.tophat}
                      subject={keepYourHeartPumping.subject}
                      title={keepYourHeartPumping.title}
                      duration={keepYourHeartPumping.duration}
                      description={keepYourHeartPumping.description}
                      img={keepYourHeartPumping.img}
                      actions={keepYourHeartPumping.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={bodySystems.tophat}
                      subject={bodySystems.subject}
                      title={bodySystems.title}
                      duration={bodySystems.duration}
                      description={bodySystems.description}
                      img={bodySystems.img}
                      actions={bodySystems.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={deepDiveIntoType2Diabetes.tophat}
                      subject={deepDiveIntoType2Diabetes.subject}
                      title={deepDiveIntoType2Diabetes.title}
                      duration={deepDiveIntoType2Diabetes.duration}
                      description={deepDiveIntoType2Diabetes.description}
                      img={deepDiveIntoType2Diabetes.img}
                      actions={deepDiveIntoType2Diabetes.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              { whatsTheDifference?.tophat &&
              (<Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={whatsTheDifference.tophat}
                      subject={whatsTheDifference.subject}
                      title={whatsTheDifference.title}
                      duration={whatsTheDifference.duration}
                      description={whatsTheDifference.description}
                      img={whatsTheDifference.img}
                      actions={whatsTheDifference.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>)}
            </Container>
          </Section>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Section className="bg-color-purple" id="healthy-lifestyle">
              {/* Health Lifestyle */}
              <Container>
                <h2 className="color-white">
                  {t('volunteersHealthyLifestyleHeading').toUpperCase()}
                </h2>
              </Container>
            </Section>
          </ToggleClassOnVisible>
          <Section>
            <Container>
              <Row>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[0]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={riskyChoices.tophat}
                      subject={riskyChoices.subject}
                      title={riskyChoices.title}
                      duration={riskyChoices.duration}
                      description={riskyChoices.description}
                      img={riskyChoices.img}
                      actions={riskyChoices.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  <ToggleClassOnVisible
                    delay={fadeInDelays[1]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={bedtime.tophat}
                      subject={bedtime.subject}
                      title={bedtime.title}
                      duration={bedtime.duration}
                      description={bedtime.description}
                      img={bedtime.img}
                      actions={bedtime.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
                <Column size={4}>
                  {' '}
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={healthNewsInTheKnow.tophat}
                      subject={healthNewsInTheKnow.subject}
                      title={healthNewsInTheKnow.title}
                      duration={healthNewsInTheKnow.duration}
                      description={healthNewsInTheKnow.description}
                      img={healthNewsInTheKnow.img}
                      actions={healthNewsInTheKnow.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>
              { stressOut?.tophat && (
              <Row>
                <Column size={4}>
                  {' '}
                  <ToggleClassOnVisible
                    delay={fadeInDelays[2]}
                    extraClasses="appear-on-visible"
                    style={{ height: '100%' }}
                  >
                    <Resource
                      tophat={stressOut.tophat}
                      subject={stressOut.subject}
                      title={stressOut.title}
                      duration={stressOut.duration}
                      description={stressOut.description}
                      img={stressOut.img}
                      actions={stressOut.actions}
                      locked
                    />
                  </ToggleClassOnVisible>
                </Column>
              </Row>)}
            </Container>
          </Section>
          <Section className="pb-0">
            {/* CALLOUT */}
            <ToggleClassOnVisible extraClasses="appear-on-visible">
              <Container fullWidth>
                <Callout
                  to="https://abbottegc.benevity.org/user/login"
                  className="callout--volunteer"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: t('resourcesCallout') }}
                  />
                </Callout>
              </Container>
            </ToggleClassOnVisible>
          </Section>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Section>
              {/* Related Resources */}
              <Container>
                <h2 className="h3 text-serif">
                  {t('volunteersRelatedResourcesHeading')}
                </h2>
                <Row>
                  <Column size={6}>
                    <AdditionalResources
                      img="img-addl-resources-educators.jpg"
                      heading={t('volunteersRelatedResourcesText1')}
                      to={'/educators'}
                    />
                  </Column>
                  <Column size={6}>
                    <AdditionalResources
                      img="img-addl-resources-family.jpg"
                      heading={t('volunteersRelatedResourcesText2')}
                      to={'/for-families/'}
                    ></AdditionalResources>
                  </Column>
                </Row>
              </Container>
            </Section>
          </ToggleClassOnVisible>
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query VolunteersResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            download
            noIcon
            asLink
            inverse
            virtual
            moduleName
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default Volunteers;
