import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import OauthContext from '../../context/OauthContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const validTypes = ['ardx', 'abbott', 'logout'];

/**
 * Button to initiate the Oauth process. We use a real anchor
 * instead of Gatsby-link because this page only exists in PHP
 * NOT in gatsby SPA.
 *
 * For now the logout button does NOT have any CSRF protection
 * other than the fact that PHPSESSIONID has SameSite 'strict' set.
 */
const OauthButton = ({ type }) => {
  const oauthContext = useContext(OauthContext);
  const [enabled, setEnabled] = useState(true);
  const { t } = useTranslation('common');
  const isValid = validTypes.indexOf(type.toLowerCase()) >= 0;

  const clickHandler = (e) => {
    if (!enabled) {
      return;
    }

    if (type.toLowerCase() === 'logout') {
      e.preventDefault();
      // Disable the button to prevent redundant logout calls.
      setEnabled(false);
      oauthContext.setLoggedIn(false);
      axios
        .post(e.target.getAttribute('href'))
        .then((reponse) => {
          // Do nothing on success, we already called .setLoggedIn(false)
        })
        .catch((err) => {
          console.error('Error detected during logout');
        })
        .then(() => {
          // Re-enable the button
          setEnabled(true);
        });
    }
  };

  const renderButton = () => {
    return (
      <a
        className={`button button--oauth button--oauth--${type.toLowerCase()}`}
        onClick={clickHandler}
        href={`/app/oauth/${
          type?.toLowerCase() === 'logout' ? 'logout' : 'login'
        }`}
      >
        {type?.toLowerCase() === 'logout'
          ? t('logout')
          : `${type} ${t('sign-in')}`}
      </a>
    );
  };

  return <>{isValid && oauthContext.ready && renderButton()}</>;
};

OauthButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default OauthButton;
